import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { StaticImage } from 'gatsby-plugin-image';
import { jsx as ___EmotionJSX } from "@emotion/react";
var commands = { echo: function echo() { for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
    } return args.join(" "); }, cat: function cat() { return "Meow!"; }, _10: function _10() { return [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, "Blastoff!"].join("\n"); }, emacs: function emacs() { return "I'd rather not..."; }, vim: function vim() { return "I would love to"; }, hello: function hello() { return "Hi!"; }, molly: function molly() { return "Let's stay happy forever"; }, love: function love() { return ___EmotionJSX(StaticImage, { src: "./molly-and-me.jpg", alt: "Molly and Me", __imageData: require("/home/runner/work/zmbush.com/zmbush.com/main/.cache/caches/gatsby-plugin-image/1812366842.json") }); }, bunnies: function bunnies() { var imgs = ["http://25.media.tumblr.com/797ca7857d59a583c48bd3bdf399587a/tumblr_mkbsuybwbx1s4n655o1_250.gif", "http://25.media.tumblr.com/556ece8d2e8a37778a669fec46cfc048/tumblr_mk5wu4khsl1s2g4gpo1_500.gif", "http://25.media.tumblr.com/ac4cba33c1911ee5bca6e1d6eccc8848/tumblr_mk03o8tA8I1s5fgmlo1_500.gif", "http://25.media.tumblr.com/42be686f0a3d3d3ba1f79884941ae212/tumblr_mjjmwtfb0i1s23il0o1_250.gif", "http://media.tumblr.com/c5c16cd17c48cdeb5ea946c6dec3ec4d/tumblr_inline_mjh6nnIzUU1qa3yy9.gif", "http://25.media.tumblr.com/b34d516ad3c8b5cc60a4b1e0e829d3f3/tumblr_mjf5id4UDO1s2g4gpo1_500.gif", "http://25.media.tumblr.com/d5e5d7f09078b318652400ac836c3a92/tumblr_mjf4umnVW91s2g4gpo1_500.gif", "http://24.media.tumblr.com/d6555aee4cfe75dbad720cb754e09679/tumblr_mj64zt00qb1s5fgmlo1_500.gif"]; var img = imgs[Math.floor(Math.random() * imgs.length)]; return ___EmotionJSX("img", { src: img, alt: "bunny" }); }, help: function help() { var commandNames = Object.getOwnPropertyNames(commands).map(function (cmd) { if (cmd[0] === "_") {
        return cmd.slice(1);
    } return cmd; }); commandNames.sort(); return ___EmotionJSX("div", null, "Available Commands:", " ", commandNames.map(function (cmd) { return ___EmotionJSX("span", null, cmd, " "); })); } };
var Sh = { resultOf: function resultOf(command) { var _command$split = command.split(" "), binary = _command$split[0], args = _command$split.slice(1); if (typeof commands[binary] === "function") {
        return commands[binary].apply(commands, _toConsumableArray(args));
    } if (typeof commands["_" + binary] === "function") {
        return commands["_" + binary].apply(commands, _toConsumableArray(args));
    } if (binary === "") {
        return "";
    } return binary + ": command not found"; } };
export default Sh;
